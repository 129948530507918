/* GenerarFichas.js */

import HotspotsSrv from '@/services/hotspot/HotspotsSrv.js'
import FichaTipoSrv from '@/services/hotspot/FichaTipoSrv.js'
import FichasLayoutSrv from '@/services/hotspot/FichasLayoutSrv.js'
import VendedorSrv from '@/services/hotspot/VendedorSrv.js'
import FichasSrv from '@/services/hotspot/FichasSrv.js'

import { todoGetters } from "@/state/helpers";
import Swal from 'sweetalert2'

import Layout from "@/views/layouts/main"
export default {
  name: 'GenerarFichas',
  components:{ Layout },
  data() {
    return {
      modo: 'generar',
      bloques : [],

      id_tipo: null,
      id_layout: null,
      id_hotspot: null,
      id_vendedor: null,
      cantidad: 1,
      tipo_autenticacion: 1,    // 1 = pin, 2 = usuario y contraseña
      prefijo_pin: '',
      longitud_pin: 3,
      tipo_pin: 'numerico',     // numerico || alfanumerico
      longitud_clave: 3,
      tipo_clave: 'numerico',   // numerico || alfanumerico
      precio: 0,
      
      con_prefijo: false,
      spin_generar: false,

      tipos: [],
      layouts: [],
      hotspots: [],
      vendedores: []
    }
  },

  computed: {
    ...todoGetters,
     usuario: function() {
       var self = this
       return self.$store.state.todo.usuario
     },
    usuarioActEsAdmin() {
      return this.usuarioActualEsAdministrador()
    },
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },

  watch: {
    id_layout: function (newPortal, oldPortal){
      var self = this
      self.obtenerTipoAutenticacion()
    },
    uc (estaCargado) {
      let self = this

      // Si el usuario no está cargado se evita continuar
      if(!estaCargado) return 

      if (self.usuarioActEsAdmin) 
        self.cargarVendedores()
      else 
        self.id_vendedor = self.usuario.id
    }
  },

  created(){
    var self = this

    // Determinación del modo
    if (self.$route.path.indexOf('fichas/generar') != -1) self.modo = 'generar'
    else self.modo = 'edicion'

    self.cargarHotspots()
    self.cargarLayouts()
    self.cargarTiposFicha()

    // Carga de la última configuración
    let configuracion = window.localStorage.getItem('argusblack.hotspot.generarFichas.configuracion')

    if (configuracion != undefined) {
      configuracion = JSON.parse(configuracion)
      self.id_hotspot = configuracion.id_hotspot
      self.id_tipo = configuracion.id_tipo
      self.id_layout = configuracion.id_layout
      self.id_vendedor = configuracion.id_vendedor
      self.cantidad = configuracion.cantidad
      self.longitud_ping = configuracion.longitud_pin
      self.tipo_pin = configuracion.tipo_pin
      self.con_prefijo = configuracion.con_prefijo
      self.tipo_autenticacion = configuracion.tipo_autenticacion
    }


    // Si el usuario está cargado y es administrador
    if (self.uc) {
      if (self.usuarioActEsAdmin) {
        if (self.$route.query.id_vendedor) self.id_vendedor = parseInt(self.$router.query.id_vendedor)

        self.cargarVendedores()
      }
      else
        self.id_vendedor = self.usuario.id
    }
  },

  methods: {
    agregarBloque: function() {
      var self = this

      if (!self.verificarDatosCorrectos()) return

      var nuevoBloque = {
        id_hotspot: self.id_hotspot,
        id_tipo: self.id_tipo,
        id_layout: self.id_layout,
        id_vendedor: self.id_vendedor,
        cantidad: self.cantidad,
        longitud_pin: self.longitud_pin,
        tipo_pin: self.tipo_pin,
        con_prefijo: self.con_prefijo,
        tipo_autenticacion: self.tipo_autenticacion
      }

      window.localStorage.setItem('argusblack.hotspot.generarFichas.configuracion', JSON.stringify(nuevoBloque))

      var tipo = self.tipos.filter(tipo => tipo.id == self.id_tipo)
      let vendedor = null;
      
      if ( self.usuarioActEsAdmin ) 
        vendedor = self.vendedores.filter(vendedor => vendedor.id == self.id_vendedor)[0]
      else 
        vendedor = self.usuario

      Object.assign(nuevoBloque,{
        descripcion_tipo: tipo[0].descripcion,
        vendedor: vendedor.nombre+' '+(vendedor.apellido_paterno?vendedor.apellido_paterno:'')+' '+(vendedor.apellido_materno?vendedor.apellido_materno:'')
      })

      if(self.con_prefijo) {
        Object.assign(nuevoBloque,{
          prefijo_pin: self.prefijo_pin
        })
      }

      if(self.tipo_autenticacion == 2){
        Object.assign(nuevoBloque,{
          longitud_clave: self.longitud_clave,
          tipo_clave: self.tipo_clave,
        })
      }

      self.bloques.push(nuevoBloque)
    },

    atras: function() {
      this.$router.go(-1)
    },

    cargarHotspots: function(){
      var self = this

      HotspotsSrv.hotspotsJSON().then(response => {
        self.hotspots = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = "No se pudo cargar los hotspots"
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarLayouts: function() {
      var self = this, params = {}

      self.id_layout = null
      self.tipo_autenticacion = 1

      var index = self.hotspots.findIndex(function (hotspot) {
        return hotspot.id == self.id_hotspot
      })

      if(index != -1){
        params = {
          tipo_autenticacion: self.hotspots[index].tipo_autenticacion
        }
      }
      
      FichasLayoutSrv.fichasLayoutsJSON(params).then(response => {
        self.layouts = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'No se pudieron cargar los layouts de fichas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarTiposFicha: function(){
      var self = this, params = { solo_con_perfil: true }

      FichaTipoSrv.fichasTiposJSON(params).then(response => {
        self.tipos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'No se pudieron cargar los tipos de fichas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarVendedores: function () {
      var self = this

      VendedorSrv.vendedoresJSON().then(response => {
        self.vendedores = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'No se cargaron los vendedores'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      var self = this
      self.atras()
    },

    eliminarBloque: function(index) {
      var self = this
      self.bloques.splice(index, 1)
    },

    generar: function() {
      var self = this

      if(self.bloques.length == 0) {
        iu.msg.warning('No se han registrado ningun bloque lo cual no se generaran las fichas')
        self.spin_generar = false
        return
      }

      var bloques = []
      var cantidadTotal = 0

      self.bloques.forEach(bloque => {
        var nuevoBloque = {
          id_hotspot: bloque.id_hotspot,
          id_tipo: bloque.id_tipo,
          id_layout: bloque.id_layout,
          id_vendedor: bloque.id_vendedor,
          cantidad: bloque.cantidad,
          longitud_pin: bloque.longitud_pin,
          tipo_pin: bloque.tipo_pin,
          tipo_autenticacion: bloque.tipo_autenticacion
        }
        if(bloque.con_prefijo) {
          Object.assign(nuevoBloque,{
            prefijo_pin: bloque.prefijo_pin
          })
        }
        
        if(bloque.tipo_autenticacion == 2){
          Object.assign(nuevoBloque,{
            longitud_clave: bloque.longitud_clave,
            tipo_clave: bloque.tipo_clave
          })
        }

        cantidadTotal += parseInt(bloque.cantidad)
        bloques.push(nuevoBloque)
      })

      var fichas = {
        bloques: bloques,
        cantidad: cantidadTotal
      }

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      FichasSrv.generar(fichas).then(response => {
        self.spin_generar = false

        swal.fire({
          title: 'Impresión de fichas',
          html: '¿Deseas imprimir las fichas generadas?',
          icon: "question",
          confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
          cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
          showCancelButton: true
        }).then(result => {
          if (result.value) {
            let idsFichasTmp = response.data
            let idsFichas = []
            
            idsFichasTmp.forEach(function(bloqueIds){
              idsFichas = idsFichas.concat(bloqueIds)
            })

            self.atras()
            
            let rutaDestino = self.$router.resolve({name: 'imprimirFichasHotspot', query: { ids_fichas: idsFichas }});
            window.open(rutaDestino.href, '_blank');
          } else if ( result.dismiss === Swal.DismissReason.cancel ) {
            swal.fire("Generar", 'Se generaron correctamente las fichas', "success");
            self.atras()
          }
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se generaron las fichas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.spin_generar = false
      })
    },

    obtenerPrecio: function () {
      var self = this

      var tipo = self.tipos.filter(tipo => tipo.id == self.id_tipo)
      self.precio = tipo[0].precio
    },

    obtenerTipoAutenticacion: function () {
      var self = this

      self.tipo_autenticacion = 1

      var index = self.layouts.findIndex(function (layout) {
        return layout.id == self.id_layout
      })

      if(index == -1) return
      
      self.tipo_autenticacion = self.layouts[index].tipo_autenticacion
    },

    verificarDatosCorrectos(){
      var self = this

      if(self.id_hotspot == null) {
        iu.msg.warning('Es necesario registrar un hotspot')
        self.$refs.hotspot.focus()
        return false
      }

      if(self.id_tipo == null){
        iu.msg.warning('Es necesario registrar un tipo de ficha')
        self.$refs.tipo_ficha.focus()
        return false
      }

      if(self.id_layout == null) {
        iu.msg.warning('Es necesario registrar un layout de ficha')
        self.$refs.layout.focus()
        return false
      }
      
      if(self.id_vendedor == null){
        iu.msg.warning('Es necesario registrar un vendedor')
        self.$refs.vendedor.focus()
        return false
      }

      if(self.cantidad < 1 || self.cantidad == '' || self.cantidad == null) {
        iu.msg.warning('La cantidad de fichas a registrar no puede ser menor a 1 o sin dato registrado')
        self.$refs.cantidad.focus()
        return false
      }

      if(self.cantidad > 2000) {
        iu.msg.warning('No puede rebasar la cantidad de 100 fichas')
        self.$refs.cantidad.focus()
        return false
      }

      if(self.con_prefijo && self.prefijo_pin == ''){
        iu.msg.warning('Es necesario registar un prefijo')
        self.$refs.prefijo.focus()
        return false
      }

      return true
    }
  }
}