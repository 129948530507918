<template>
  <Layout :tituloPagina="`Hotspot | Fichas | ${modo == 'generar' ? 'Generar' : 'Edición'}`">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">{{ modo == 'generar' ? 'Generar' : 'Edición de' }} Fichas</h4>
        <div>
          <a href="#" onclick="return false" @click="cerrar()">
            <i class="mdi mdi-close text-dark"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3" v-show="usuarioActEsAdmin">
            <label>Vendedor</label>
            <div class="input-group">
              <select
                name="vendedor"
                ref="vendedor"
                v-model="id_vendedor"
                class="form-select"
              >
                <option :value="null">Seleccionar</option>
                <option
                  v-for="vendedor in vendedores"
                  :key="vendedor.id"
                  :value="vendedor.id"
                >
                  {{vendedor.nombre}}
                  {{vendedor.apellido_paterno ? vendedor.apellido_paterno : ''}}
                  {{vendedor.apellido_materno ? vendedor.apellido_materno : ''}}
                </option>
              </select>
              <router-link
                :to="{ name: 'nuevoVendedorHotspot' }"
                class="btn btn-success"
                title="Nuevo vendedor"
              >
                <i class="mdi mdi-plus-thick"></i>
              </router-link>
            </div>
          </div>
          <div class="col-md-3">
            <label>Hotspot</label>
            <div class="input-group">
              <select
                name="hotspot"
                ref="hotspot"
                v-model="id_hotspot"
                class="form-select"
                @change="cargarLayouts()"
              >
                <option :value="null">Seleccionar</option>
                <option
                  v-for="hotspot in hotspots"
                  :key="hotspot.id"
                  :value="hotspot.id"
                >
                  {{hotspot.nombre}}
                </option>
              </select>
              <router-link
                :to="{ name: 'nuevoHotspot' }"
                class="btn btn-success"
                title="Nuevo hotspot"
              >
                <i class="mdi mdi-plus-thick"></i>
              </router-link>
            </div>
          </div>
          <div class="col-md-3">
            <label>Tipo de ficha</label>
            <div class="input-group">
              <select
                name="tipo_ficha"
                ref="tipo_ficha"
                v-model="id_tipo"
                class="form-select"
                @change="obtenerPrecio()"
              >
                <option :value="null">Seleccionar</option>
                <option
                  v-for="tipo in tipos"
                  :key="tipo.id"
                  :value="tipo.id"
                >
                  {{tipo.descripcion}}
                </option>
              </select>
              <router-link
                :to="{ name: 'nuevoFichaTipoHotspot' }"
                class="btn btn-success"
                title="Nuevo tipo de ficha"
              >
                <i class="mdi mdi-plus-thick"></i>
              </router-link>
            </div>
          </div>
          <div class="col-md-3">
            <label>layout de ficha</label>
            <div class="input-group">
              <select
                name="layout"
                ref="layout"
                v-model="id_layout"
                class="form-select"
                :disabled="id_hotspot == null"
                :readonly="id_hotspot == null"
              >
                <option :value="null">Seleccionar</option>
                <option
                  v-for="layout in layouts"
                  :key="layout.id"
                  :value="layout.id"
                >
                  {{layout.nombre}}
                </option>
              </select>
              <router-link
                :to="{ name: 'nuevoFichasLayoutHotspot' }"
                class="btn btn-success"
                title="Nuevo layout de ficha"
              >
                <i class="mdi mdi-plus-thick"></i>
              </router-link>
            </div>
          </div>
        </div>
        <br>
        <h4>Configuración de las fichas</h4>
        <hr style="margin-top: 1rem; margin-bottom: 1rem;">
        <div class="row">
          <div class="col-md-3">
            <label for="autenticacion">
              Tipo de autenticación
            </label>
            <input
              type="text"
              class="form-control fw-semibold"
              id="autenticacion"
              :value="tipo_autenticacion == 1 ? 'PIN' : 'Usuario y contraseña'"
              readonly
              disabled
              :class="{
                'text-warning' : tipo_autenticacion == 2,
                'text-success' : tipo_autenticacion == 1
              }"
            />
          </div>
          <div class="col-md-3 col-6">
            <label for="logitudPin">Longitud del PIN</label>
            <select id="logitudPin" v-model="longitud_pin" class="form-select">
              <option v-for="(num,index) in 10" :key="`logitud-pin-${index}`" :value="num">{{num}}</option>
            </select>
          </div>
          <div class="col-md-3 col-6">
            <label for="tipoPin">Tipo de PIN</label>
            <select id="tipoPin" v-model="tipo_pin" class="form-select">
              <option value="alfanumerico">Alfanumérico</option>
              <option value="numerico">Numérico</option>
            </select>
          </div>
          <div class="col-md-3 col-6">
            <label for="precio">Precio</label>
            <div class="input-group">
              <span class="input-group-text">
                {{ monedaSistema.simbolo }}
              </span>
              <input
                type="number" class="form-control"
                id="precio" name="cantidad"
                ref="cantidad" readonly
                disabled v-model="precio"
              />
              <span class="input-group-text">
                {{ monedaSistema.codigo }}
              </span>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-3" v-if="tipo_autenticacion == 2">
            <label for="logitudPin">Longitud de la clave</label>
            <select id="logitudPin" v-model="longitud_clave" class="form-select">
              <option v-for="(num,index) in 10" :key="`logitud-clave-${index}`" :value="num">{{num}}</option>
            </select>
          </div>
          <div class="col-md-3" v-if="tipo_autenticacion == 2">
            <label for="tipoPin">Tipo de clave</label>
            <select id="tipoPin" v-model="tipo_clave" class="form-select">
              <option value="alfanumerico">Alfanumérico</option>
              <option value="numerico">Numérico</option>
            </select>
          </div>
          <div class="col-md-3">
            <div class="row">
              <div :class="con_prefijo ? 'col-sm-6 col-7' : 'col-sm-12'">
                <label for="">Con Prefijo de PIN</label>
                <div class="form-check form-switch form-switch-lg">
                  <input class="form-check-input" type="checkbox" id="conPrefijo" v-model="con_prefijo">
                  <label class="form-check-label" for="conPrefijo"></label>
                </div>
              </div>
              <div class="col-sm-6 col-5" v-show="con_prefijo">
                <label for="prefijoPin">Prefijo del PIN</label>
                <input
                  type="text" class="form-control"
                  id="prefijoPin" v-model="prefijo_pin"
                  placeholder="PL1" maxlength="3"
                  name="prefijo" ref="prefijo"
                />
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-3 col-6">
            <label for="cantidad">Cantidad</label>
            <input
              type="number"
              class="form-control"
              id="cantidad"
              name="cantidad"
              v-model="cantidad"
            >
          </div>
          <div class="offset-md-7 col-md-2 p-2">
            <div class="d-grid gap-2">
              <button class="btn btn-outline-success" @click="agregarBloque()">
                <i class="mdi mdi-plus-thick"></i>
                Agregar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">{{ modo == 'generar' ? 'Generar' : 'Edición de' }} Fichas</h4>
        <div>
          <a href="#" onclick="return false" @click="cerrar()">
            <i class="mdi mdi-close text-dark"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="tblRouters table table-hover align-middle table-nowrap">
            <thead>
              <tr>
                <th style="width: 50px">No.</th>
                <th>Tipo de autenticación</th>
                <th style="width: 300px">Tipo de ficha</th>
                <th>Vendedor</th>
                <th>Prefijo de PIN</th>
                <th>Tipo de Pin</th>
                <th>Cantidad</th>
                <th style=" width: 50px">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(bloque,index) in bloques" :key="`bloque-${index+1}`">
                <td>{{index+1}}</td>
                <td>
                  <span
                    :class="{
                      'text-warning' : bloque.tipo_autenticacion == 2,
                      'text-success' : bloque.tipo_autenticacion == 1
                    }"
                  >
                    <strong>
                      {{bloque.tipo_autenticacion == 1 ? 'PIN' : 'Usuario y contraseña'}}
                    </strong>
                  </span>
                </td>
                <td>{{bloque.descripcion_tipo}}</td>
                <td>{{bloque.vendedor}}</td>
                <td :class="bloque.prefijo_pin ? '': 'text-info'">
                  {{bloque.prefijo_pin? bloque.prefijo_pin: 'Sin prefijo'}}
                </td>
                <td>{{bloque.tipo_pin=='numerico'?'Numérico':'Alfanumérico'}}</td>
                <td>{{bloque.cantidad}}</td>
                <td class="text-right">
                  <button class="btn btn-danger btn-sm" @click="eliminarBloque(index)">
                    <i class="mdi mdi-close"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-right">
      <div class="btn-group">
        <button @click="atras()" class="btn btn-secondary">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
        <button
          class="btn btn-success"
          :disabled="spin_generar"
          @click="
            spin_generar = true,
            generar()
          "
        >
          <i class="mdi mdi-cog-outline" :class="spin_generar ? 'mdi-spin' : ''"></i>
          Generar
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script src="./GenerarFichas.js"></script>
